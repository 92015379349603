	$(
		 function() {
			 app.initFloatingHeader({
				 floatSelector: '._ > .top',
				 floatFix: 48
			 });
			 app.assignNumberControl();
             app.attachSvgEvents();
			 app.setSelectedLink();
			 app.addRegisterCheckBoxes();
			 app.makeSocialButtons();
             app.addCoverSpan();
             app.correctTopMenuPopups(
                 {mode: 'advanced'}
             );
			 app.setBackUrls();
			 app.saveProductListInfo();
			 app.assignProductOver();
			 app.initializeScrollButton();

             app.hamburger.init({look: 1});

			 setTimeout(
				function() {
					app.setMenuColors();
				},
				 100
			 );
		 }
	);

	var app = {
        _vars: {
            _guid: 'e8c67a2d-48cd-4a21-b731-5aa581d29972'
        },
        _VERSION: 2,
        _VERSION_TRANS_NOT_COMPLETED: true,
        prodItemCorrection: -30,
		indexLinkPos : 0,
		messages : {},
		productAvailableQuantityUrl : null,
		optionsWereInitialized : false,
		doAddPlIcons: function ($item, html) {
			$item.find('> .image').append(html);
			$item.find('.pl-icons > div').addClass('udu__jelly-item');
		},
		showSearch : function() {
			if ($('.li-search .ul-mega-1').hasClass('hidden')) {
				$('.li-search').addClass('hover');
				$('.li-search .ul-mega-1, .li-search .delimiter').removeClass('hidden');
			} else {
				$('.li-search').removeClass('hover');
				$('.li-search .ul-mega-1, .li-search .delimiter').addClass('hidden');
			}
		},
		doAddColorsAttributesToListItem: function($item, html) {
			$item.find('.image').append(html);

		},
		setMenuColors : function() {
			$('._ .menu > ul > li.with-children > div.top-title')
				.append('<div class="menu-arrow"><div class="menu-over-color"></div></div>');

			$('.menu-arrow').fadeIn();
            $('.ul-mega-1, .ul-mega-2').addClass('schema-brd-color');

			var menuOverColor = $('._ .menu-over-color').css('color');
			var menuSelColor = $('._ .menu li.selected a').css('color');

			$("<style type='text/css'> " +
				 "._ .menu > ul > li:hover {border-top: 3px solid " + menuOverColor + " !important} " +
				 "._ .menu > ul > li.selected {border-top: 3px solid " + menuSelColor + " !important} " +
				 "._ .menu li:hover .menu-arrow div.menu-over-color {background-color: " + menuOverColor + " !important} " +
				 "</style>")
				 .appendTo("head");
		},
		doBeforePriceSliderShown: function(trg) {
			var color = app.getClassColor('schema-color');
			$('.noUi-connect').css('background-color', color);
		}
	};
